// import React, { useEffect, useState } from 'react'
// import Invoiceslip from './Invoiceslip'
// import './Invoice.scss'
// import { useParams } from 'react-router-dom'
// // import { jsPDF } from 'jspdf';
// // import pdfMake from 'pdfmake/build/pdfmake';
// // import pdfFonts from 'pdfmake/build/vfs_fonts';
// import InvoicePdfTemplate from './InvoicePdfTemplate';
// import Button from '../../Components/Homepage/Button/Button';
// import { useSelector } from 'react-redux';
// // import html2pdf from 'html2pdf.js';
// import { dashboardAPI } from '../../Api';
// import { toast } from 'react-toastify';
// import { cartAPI } from '../../Api';
// import Loader from '../../Components/Loader';
// import get from 'lodash/get';

// export default function Invoice(props) {
//     const { orderid } = useParams();
//     return (
//         <>
//             <div className="invoice-container">
//                 <Invoiceslip id={orderid} />
//             </div>
//         </>
//     )
// }
import React, { useEffect, useState } from "react";
import Invoiceslip from "./Invoiceslip";
import "./Invoice.scss";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InvoicePdfTemplate from "./InvoicePdfTemplate";
import Button from "../../Components/Homepage/Button/Button";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { dashboardAPI } from "../../Api";
import { toast } from "react-toastify";
import { cartAPI } from "../../Api";
import Loader from "../../Components/Loader";
import get from "lodash/get";
import { fetchChallans } from "../../Api/cartAPI";
import InclusiveChallan from "./InclusiveChallan";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const mapStateToProps = ({ auth }) => ({
  auth,
});
export default function Challan(props) {
  const { auth } = useSelector(mapStateToProps);

  const { orderid } = useParams();
  const challanId = Number(orderid);
  const [orderDetails, setOrderDetails] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [entity, setEntity] = useState("");
  const [challanDetails, setChallanDetails] = useState(null);
  const [challanTemplate,setChallanTemplate] = useState(null);
  const selectedEntity = [];
  
  const fetchInvoiceDetails = async () => {
    try {
      const res = await fetchChallans({ orderId: challanId });
      if (res.data.status === 400) {
        setInvoice({});
        toast.error("No invoice found");
      } else {
        const challandata = get(res, "data", {});
        setChallanDetails(challandata)
        setChallanTemplate(challandata?.template)
      }
    } catch (error) {
      console.log(error);
      setInvoice({});
    }
  };

  const doc = new jsPDF();

  const handleDownloadInvoice = () => {
    if (auth.tenantDetails) {
      const inputElement = document.getElementById("invoice-slip-pdf");

      if (inputElement) {
        html2pdf(inputElement, {
          margin: 10,
          filename: "invoice.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
          .set()
          .then((pdf) => {
            const blob = pdf.output("blob");
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "output.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          });
      } else {
        toast.error("order details not fixed yet");
      }
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);
  return challanDetails ? (
    <>
    
      <div
        className="generate-invoice-entity"
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f8f9fa", // Light gray background for a classy look
          padding: "40px 20px",
          boxSizing: "border-box",
        }}
      >

      </div>

      <div className="">
        {
            challanTemplate == 12 ? 
            <InclusiveChallan challanDetails={challanDetails} /> :
            <Invoiceslip id={orderid} invoice={challanDetails} challanTemplate={challanTemplate}/> 
        }
        <div
          style={{
            transform: "translateX(200%)",
            position: "absolute",
            display: "none",
          }}
        >
          {/* <InvoicePdfTemplate
            id={orderid}
            userDetails={auth.userDetails}
            orderDetails={challanDetails}
            invoice={challanDetails}
            tenantDetails={auth.tenantDetails}
          /> */}
        </div>
      </div>

      {/* <div style={{
                backgroundColor :'#f2f2f2',
                display : 'flex',
                justifyContent : 'center', 
                paddingBottom : '2rem'
            }}>
                 <Button
                   className="download-button"
                    text="Download Invoice"
                    clicker={handleDownloadInvoice}
                />
                <button className='download-button' onClick={handleDownloadInvoice}>
                Download Challan
                </button>
            </div> */}
            
    </>
  ) : (
    <div className="loader">
      <Loader />
    </div>
  );
}
