import React from "react";
import price_in_words from "../../Utils/price-in-words";
import { number2text } from "../Notes/Notes";
import { useState } from "react";

const InclusiveChallan = ({ challanDetails }) => {
  const [isDiscount, setIsDiscount] = useState(false);
  function customCeil(num) {
    const decimal = num % 1; // Get the decimal part
    const intPart = Math.floor(num); // Get the integer part

    if (decimal >= 0.5) {
      return Math.ceil(num); // Ceil to 1 decimal place
    } else {
      return Math.floor(num); // Floor to 1 decimal place
    }
  }
  return (
    <div className="invoice-container" style={{ padding: "0px" }}>
      <div className="upper-section">
        <div className="left-upper-section">
          <span
            style={{ fontWeight: "bold", marginTop: "5px", fontSize: "15px" }}
          >
            {challanDetails?.tenant_details?.name.toUpperCase()}
          </span>
          <span>{challanDetails?.tenant_details?.address}</span>
          <span>
            Phone :{" "}
            {challanDetails?.tenant_details?.business_details?.contact_number}
          </span>
          <span style={{ marginTop: "5px" }}>
            E-Mail : {challanDetails?.tenant_details?.business_details?.email}
          </span>
        </div>
        <div className="right-upper-section">
          <span
            style={{ marginTop: "5px", fontSize: "15px", fontWeight: "bold" }}
          >
            {challanDetails.entity?.title.toUpperCase()}
          </span>
          <p style={{ fontSize: "11px" }}>
            <span style={{ fontWeight: "bold", fontSize: "13px" }}>
              Address :{" "}
            </span>
            {challanDetails.entity?.address}
          </p>
          <p style={{ fontWeight: "bold" }}>
            D.L NO.{" "}
            <span style={{ fontSize: "12px" }}>
              {challanDetails.entity?.dl_number}
            </span>
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            GSTIN. :
            <span style={{ fontWeight: "lighter" }}>
              {" "}
              {challanDetails.entity?.gstin}
            </span>
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            PH NO.
            <span style={{ fontWeight: "lighter" }}>
              {" "}
              {challanDetails.entity?.phone_number}
            </span>
          </p>
        </div>
      </div>
      <div className="middle-section">
        <div
          className="middle-left-section"
          style={{
            borderRight: "2px solid black",
            display: "flex",
            flexDirection: "column",
            gap: "3px",
          }}
        >
          <span style={{ display: "block" }}>
            GSTIN : {challanDetails?.entity?.gstin}
          </span>
          <span>Challan No. : {challanDetails?.challan_id}</span>
          {/* <span>DL NO : {invoice?.data?.tenant_details?.business_details?.drug_license_number}</span> */}
        </div>
        <div
          className="middle-center-section"
          style={{ borderRight: "2px solid black" }}
        >
          <h1
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            CHALLAN
          </h1>
        </div>
        <div className="middle-right-section">
          <span>DL NO : {challanDetails?.entity?.dl_no}</span>
        </div>
      </div>
      <div className="table-section">
        <div className="table-heading">
          <p style={{ maxWidth: "50px" }}>SN.</p>
          <p style={{ flex: "4" }}>PRODUCT_NAME</p>
          <p style={{ maxWidth: "50px" }}>PACK</p>
          <p>HSN</p>
          <p style={{ flex: "1" }}>BATCH</p>
          <p style={{ maxWidth: "50px" }}>QTY</p>
          <p>MRP</p>
          <p>RATE</p>
          <p style={{ maxWidth: "50px" }}>GST</p>
          {isDiscount && <p style={{ maxWidth: "50px" }}>Discount</p>}
          <p style={{ border: "none" }}>AMOUNT</p>
        </div>
        <div className="table-body">
          {challanDetails?.items?.map((item, idx) => {
            if (item?.discount_code) {
              setIsDiscount(true);
            }
            return (
              <div className="table-body-row" key={idx}>
                <p style={{ maxWidth: "50px" }}>{idx + 1}</p>
                <p style={{ flex: "4" }}>{item?.title}</p>
                <p style={{ maxWidth: "50px" }}>{Number(item?.quantity)}</p>
                <p>{item?.challan_details?.hsn_code}</p>
                <p style={{ flex: "1" }}>
                  {item?.challan_details?.batch_number}
                </p>
                <p style={{ maxWidth: "50px" }}>{Number(item?.quantity)}</p>
                <p>{Number(item?.challan_details?.mrp)}</p>
                <p>{item?.rate}</p>
                <p style={{ maxWidth: "50px" }}>
                  {item?.challan_details?.gst_applicable}
                </p>
                {isDiscount && <p style={{ maxWidth: "50px" }}>{item?.discount_code}</p>}
                <p style={{ border: "none" }}>
                  {Number(item?.final_price) * Number(item?.quantity)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="footer-section">
        <div className="footer-left-section">
          <div
            style={{
              paddingLeft: "10px",
              height: "4rem",
              borderBottom: "2px solid black",
            }}
          ></div>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <u style={{ fontWeight: "bold" }}>Terms & Conditions</u>
              <p>Goods once sold will not be taken back or exchanged.</p>
              <p>Bills not paid due date will attract 24% interest</p>
              <p>All disputes subject to Jurisdiction only.</p>
              <p>Prescribed Sales Tax declaration will be given</p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                BANK DETAILS: YES BANK(LUCKNOW)
              </p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                {" "}
                A/c No: 001863300007237,IFSC:YESB0000018{" "}
              </p>
              <p style={{ marginBottom: "5px" }}>
                Rs.{" "}
                {number2text(customCeil(Number(challanDetails?.final_price)))}{" "}
                only/-
              </p>
            </div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                For {challanDetails?.tenant_details?.name}
              </p>
              <p style={{ fontWeight: "bold" }}>Authorized Signatory</p>
            </div>
          </div>
        </div>
        <div className="footer-right-section">
          <div
            style={{
              flex: "3",
              borderBottom: "2px solid black",
              padding: "5px",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>SUB TOTAL</p>
              <p>SGST</p>
              <p>CGST</p>
              <p>Round Off</p>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>{challanDetails?.sub_total}</p>
              <p>{challanDetails?.sgst}</p>
              <p>{challanDetails?.cgst}</p>
              <p>{challanDetails?.final_price}</p>
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <p style={{ fontWeight: "bold", fontSize: "15px", margin: "10px" }}>
              GRAND TOTAL.{" "}
            </p>
            <p
              style={{
                marginTop: "10px",
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Rs. {customCeil(challanDetails?.final_price)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InclusiveChallan;
