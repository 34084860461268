import React, { useEffect, useState } from "react";
import Invoiceslip from "./Invoiceslip";
import "./Invoice.scss";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InvoicePdfTemplate from "./InvoicePdfTemplate";
import Button from "../../Components/Homepage/Button/Button";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { dashboardAPI } from "../../Api";
import { toast } from "react-toastify";
import { cartAPI } from "../../Api";
import Loader from "../../Components/Loader";
import get from "lodash/get";
import price_in_words from "../../Utils/price-in-words";
import { number2text } from "../Notes/Notes";

function convertTimestamp(timestamp) {
  // Create a Date object from the timestamp
  const date = new Date(timestamp);

  // Get the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as dd-mm-yyyy
  return `${day}-${month}-${year}`;
}
function customCeil(num) {
    const decimal = num % 1; // Get the decimal part
    const intPart = Math.floor(num); // Get the integer part
  
    if (decimal >= 0.5) {
      return Math.ceil(num ) // Ceil to 1 decimal place
    } else {
      return Math.floor(num) // Floor to 1 decimal place
    }
  }

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const mapStateToProps = ({ auth }) => ({
  auth,
});
export default function Invoice(props) {
  const { auth } = useSelector(mapStateToProps);

  const { orderid } = useParams();
  const invoiceId = Number(orderid);
  const [orderDetails, setOrderDetails] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoice_status, setInvoiceStatus] = useState("CREATED");
  const [ticket, SetTicket] = useState(null);
  const [filtered_data, setFilteredata] = useState(null);
  const [isProforma, setProforma] = useState(true);
  const [isDisocunt, setIsDiscount] = useState(false)

  useEffect(async () => {
    const data = {
      id: invoiceId,
    };
    // const response = await dashboardAPI.fetchOrderDetails(data);
    // setOrderDetails(response.data);

    fetchInvoiceDetails();
  }, []);

  const fetchInvoiceDetails = async () => {
    try {
      const res = await cartAPI.fetchInvoice(Number(invoiceId));
      if (res.data.status === 400) {
        setInvoice({});
        toast.error("No invoice found");
      } else {
        const invoiceData = get(res, "data", {});
        setInvoice(invoiceData);
        if (invoiceData.data.type === "PROFORMA") {
          setProforma(false);
        }

      }

      // const res2 = await cartAPI.fetchInvoiceDetails({invoiceId});
      // // console.log(res2);
      // if (res2.data.status === 400) {
      //     setInvoice({})
      //     toast.error('No invoice found');
      // } else {
      //     const ticket = get(res2, 'data.ticket', {});
      //     SetTicket(ticket);
      //     const status = get(res2, "data.invoice_status", null);
      //     setInvoiceStatus(status);

      // }
    } catch (error) {
      console.log(error);
      setInvoice({});
    }
  };

  const doc = new jsPDF();

  const handleDownloadInvoice = () => {
    if (auth.tenantDetails) {
      const inputElement = document.getElementById("invoice-slip-pdf");
      if (inputElement) {
        html2pdf(inputElement, {
          margin: 10,
          filename: "invoice.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
          .set()
          .then((pdf) => {
            const blob = pdf.output("blob");
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "output.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          });
      } else {
        toast.error("order details not fixed yet");
      }
    }
  };

  return (
    <div className="invoice-container" style={{ padding: "0px" }}>
      <div className="upper-section">
        <div className="left-upper-section">
          <span
            style={{ fontWeight: "bold", marginTop: "5px", fontSize: "15px" }}
          >
            {invoice?.data?.tenant_details?.name.toUpperCase()}
          </span>
          <span>{invoice?.data?.tenant_details?.address}</span>
          <span>
            Phone :{" "}
            {invoice?.data?.tenant_details?.business_details?.contact_number}
          </span>
          <span style={{ marginTop: "5px" }}>
            E-Mail : {invoice?.data?.tenant_details?.business_details?.email}
          </span>
        </div>
        <div className="right-upper-section">
          <span
            style={{ marginTop: "5px", fontSize: "15px", fontWeight: "bold" }}
          >
            {invoice?.data?.entity_details?.title.toUpperCase()}
          </span>
          <p style={{ fontSize: "11px" }}>
            <span style={{ fontWeight: "bold", fontSize: "13px" }}>
              Address :{" "}
            </span>
            {invoice?.data?.entity_details?.address}
          </p>
          <p style={{ fontWeight: "bold" }}>
            D.L NO.{" "}
            <span style={{ fontSize: "12px" }}>
              {invoice?.data?.entity_details?.dl_number}
            </span>
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            GSTIN. :
            <span style={{ fontWeight: "lighter" }}>
              {" "}
              {invoice?.data?.entity_details?.gstin}
            </span>
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            PH NO.
            <span style={{ fontWeight: "lighter" }}>
              {" "}
              {invoice?.data?.entity_details?.phone_number}
            </span>
          </p>
          <p style={{ fontSize: "13px" }}>
            <span style={{ fontWeight: "bold" }}>Patient Name : </span>
            {invoice?.data?.customer_details?.customer_details?.customer_name}
          </p>
          <p style={{ fontSize: "13px" }}>
            <span style={{ fontWeight: "bold" }}>Doctor Name :</span>{" "}
            {invoice?.data?.customer_details?.doctor_details?.doctor_name}
          </p>
          <p style={{ fontSize: "13px" }}>
            <span style={{ fontWeight: "bold" }}>Surgery Date:</span>{" "}
            {invoice?.data?.customer_details?.fulfilment_date}
          </p>
          {invoice?.data?.challan_details?.challan_id &&   <p style={{ fontSize: "13px" }}>
            <span style={{ fontWeight: "bold" }}>Challan ID:</span>{" "}
            {invoice.data?.challan_details?.challan_id}
          </p>}
        </div>
      </div>
      <div className="middle-section">
        <div
          className="middle-left-section"
          style={{ borderRight: "2px solid black" }}
        >
          <span style={{ display: "block" }}>
            GSTIN : {invoice?.data?.tenant_details?.business_details?.gst}
          </span>
          <span>
            DL NO :{" "}
            {
              invoice?.data?.tenant_details?.business_details
                ?.drug_license_number
            }
          </span>
        </div>
        <div
          className="middle-center-section"
          style={{ borderRight: "2px solid black" }}
        >
          {!isProforma && (
            <h1
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              PROFORMA INVOICE
            </h1>
          )}
          {isProforma && <h1 style={{ textAlign: "center" }}>GST INVOICE</h1>}
        </div>
        <div className="middle-right-section">
          <span style={{ display: "block" }}>
            Invoice No : {invoice?.data?.invoice_id}
          </span>
          <span>Date : {convertTimestamp(invoice?.data?.created_on)}</span>
        </div>
      </div>
      <div className="table-section">
        <div className="table-heading">
          <p>SN.</p>
          <p style={{ flex: "4" }}>PRODUCT_NAME</p>
          <p>PACK</p>
          {isProforma && <p>HSN</p>}
          <p style={{ flex: "1.5" }}>BATCH</p>
          <p>QTY</p>
          <p>MRP</p>
          <p>RATE</p>
          {isProforma && <p>GST</p>}
          {isDisocunt && <p>Discount</p>}
          <p style={{ border: "none" }}>AMOUNT</p>
        </div>
        <div className="table-body">
          {invoice?.items?.map((item, idx) => {
            if(item?.discount_code){
              setIsDiscount(true)
            }
            return (
              <div className="table-body-row" key={idx}>
                <p>{idx + 1}</p>
                <p style={{ flex: "4" }}>{item?.title}</p>
                <p>{Number(item?.quantity)}</p>
                {isProforma && <p>{item?.invoice_details?.hsn_code}</p>}
                <p style={{ flex: "1.5" }}>
                  {item?.invoice_details?.batch_number}
                </p>
                <p>{Number(item?.quantity)}</p>
                <p>{Number(item?.invoice_details?.mrp)}</p>
                <p>{item?.invoice_details?.rate}</p>
                {isProforma && <p>{item?.invoice_details?.gst_applicable}</p>}
                {isDisocunt && <p>{item?.total_discount}</p> }
                <p style={{ border: "none" }}>
                  {Number(item?.final_price) * Number(item?.quantity)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="footer-section">
        <div className="footer-left-section">
          <div
            style={{
              paddingLeft: "10px",
              height: "4rem",
              borderBottom: "2px solid black",
            }}
          ></div>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <u style={{ fontWeight: "bold" }}>Terms & Conditions</u>
              <p>Goods once sold will not be taken back or exchanged.</p>
              <p>Bills not paid due date will attract 24% interest</p>
              <p>All disputes subject to Jurisdiction only.</p>
              <p>Prescribed Sales Tax declaration will be given</p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                BANK DETAILS: YES BANK(LUCKNOW)
              </p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                {" "}
                A/c No: 001863300007237,IFSC:YESB0000018{" "}
              </p>
              <p style={{ marginBottom: "5px" }}>
                Rs.{" "}
                {number2text(customCeil(Number(invoice?.data?.final_price)))}{" "}
              </p>
            </div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                For {invoice?.data?.tenant_details?.name}
              </p>
              <p style={{ fontWeight: "bold" }}>Authorized Signatory</p>
            </div>
          </div>
        </div>
        <div className="footer-right-section">
          <div
            style={{
              flex: "3",
              borderBottom: "2px solid black",
              padding: "5px",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>SUB TOTAL</p>
              {isProforma && <p>SGST</p>}
              {isProforma && <p>CGST</p>}
              <p>Round Off</p>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>{invoice?.data?.sub_total}</p>
              {isProforma && <p>{invoice?.data?.sgst}</p>}
              {isProforma && <p>{invoice?.data?.cgst}</p>}
              <p>{invoice?.data?.final_price}</p>
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <p style={{ fontWeight: "bold", fontSize: "15px", margin: "10px" }}>
              GRAND TOTAL.{" "}
            </p>
            <p
              style={{
                marginTop: "10px",
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Rs. {customCeil(invoice?.data?.final_price)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
