import React, { useState, useEffect } from "react";
import "./Invoice.scss";
import { useSelector } from "react-redux";
import { fetchChallan, fetchChallans } from "../../Api/cartAPI";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
const mapStateToProps = ({ auth }) => ({
  auth,
});

// import useReactToPrint
export default function Invoiceslip({ id, invoice, challanTemplate }) {
  console.log('inside chalan template')
  const [isDiscount , setIsDiscount] = useState(false)
  const componentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Userdata",
    onAfterPrint: () => alert("Data saved in PDF"),
  });
  let quantity = 0;

  const { auth } = useSelector(mapStateToProps);

  const {
    data: invoiceDetails = {},
    items: invoiceitem = [],
    final_delivery_charge = 0,
    packaging_charge = 0,
    promotional_discount,
    default_delivery_charge = 0,
  } = invoice || {};
  const { challan_id, final_price = 0 } = invoiceDetails || {};
  return (
    <>
      <div
        style={{
          padding: "40px",
          outline: "2px solid black",
          borderLeft: "2px solid black",
          borderRight: "2px solid black",
          
        }}
        ref={componentPDF}
      >
        <div
          className="invoice-container"
          style={{ padding: "0px", width: "auto" }}
        >
          {/* <h1 style={{ fontWeight: "bold", marginTop: "5px", fontSize: "42px", textAlign:'center' }}>
        {invoice?.tenant?.toUpperCase()}
      </h1> */}

          <div className="upper-section" style={{}}>
            <div className="left-upper-section" style={{ marginLeft: "40px" }}>
              <span
                style={{
                  marginTop: "5px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                {invoice?.tenant.toUpperCase()}
              </span>
              <span
              // style={{ marginTop: "5px", fontSize: "15px", fontWeight: "bold" }}
              >
                {invoice?.tenant_details?.address}
              </span>
              <span>
                Phone :{""}
                {invoice?.tenant_details?.business_details?.contact_number}
              </span>
              <span style={{ marginTop: "5px" }}>
                E-Mail : {invoice?.tenant_details?.business_details?.email}
              </span>
            </div>
            <div
              className="right-upper-section"
              style={{ marginLeft: "180px" }}
            >
              <span
                style={{
                  marginTop: "5px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                {invoice?.entity?.title.toUpperCase()}
              </span>
              <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                Address :<span style={{ fontWeight: "lighter" }}> </span>
                {invoice?.entity?.address}
              </p>
              <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                PH NO.
                <span style={{ fontWeight: "lighter" }}>
                  {" "}
                  {invoice?.entity?.phone_number}
                </span>
              </p>
            </div>
          </div>
          <div className="middle-section">
            <div
              className="middle-left-section"
              style={{ borderRight: "2px solid black" }}
            >
              <span style={{ display: "block" }}>
                GSTIN : {invoice?.entity?.gstin}
              </span>
              <span>Challan No. : {invoice?.challan_id}</span>
            </div>
            <div
              className="middle-center-section"
              style={{ borderRight: "2px solid black" }}
            >
              {<h1 style={{ textAlign: "center" }}>CHALLAN</h1>}
            </div>
            <div className="middle-right-section">
              <span>DL NO : {invoice?.entity?.dl_no}</span>
            </div>
          </div>
          <div className="table-section">
            <div className="table-heading">
              <p>SN.</p>
              {challanTemplate == 13 && <p>Tender Serial No.</p>}
              {challanTemplate == 14 && <p>Catalogue No.</p>}
              <p style={{ flex: "4" }}>PRODUCT_NAME</p>
              {challanTemplate == 14 && <p>HSN</p>}
              {isDiscount && <p>Discount</p>}
              <p style={{borderRight:"none"}}>Quantity</p>
              {/* { <p>HSN</p>}
          <p style={{ flex: "1.5" }}>BATCH</p>
          <p>QTY</p>
          <p>MRP</p>
          <p>RATE</p>
          { <p>GST</p>}
          <p style={{ border: "none" }}>AMOUNT</p> */}
            </div>
            <div className="table-body">
              {invoice?.items?.map((item, idx) => {
                if(item?.discount_code){
                  setIsDiscount(true)
                }
                quantity = parseInt(quantity) + parseInt(item?.quantity);
                return (
                  <div className="table-body-row" key={idx}>
                    <p>{idx + 1}</p>
                    {challanTemplate == 13 && <p>{item?.contract_serial_number}</p>}
                    {challanTemplate==14 && <p>{item?.challan_details?.catalogue_number}</p>}
                    <p style={{ flex: "4" }}>{item?.title}</p>
                    {challanTemplate==14 && <p>{item?.challan_details?.hsn_code}</p>}
                    {isDiscount && <p>{item?.discount_code}</p>}
                    <p style={{borderRight:"none"}}>{Number(item?.quantity)}</p>
                  </div>
                );
              })}
            </div>
            <div style={{ display:'flex',justifyContent:"flex-end", padding:"10px", paddingRight:"50px" }}>
              <p>Total Quantity: {quantity}</p>
            </div>
          </div>
          {/* <div className="footer-section">
        <div className="footer-left-section">
          <div
            style={{
              paddingLeft: "10px",
              height: "4rem",
              borderBottom: "2px solid black",
            }}
          ></div>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <u style={{ fontWeight: "bold" }}>Terms & Conditions</u>
              <p>Goods once sold will not be taken back or exchanged.</p>
              <p>Bills not paid due date will attract 24% interest</p>
              <p>All disputes subject to Jurisdiction only.</p>
              <p>Prescribed Sales Tax declaration will be given</p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                BANK DETAILS: YES BANK(LUCKNOW)
              </p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                {" "}
                A/c No: 001863300007237,IFSC:YESB0000018{" "}
              </p>
              <p style={{ marginBottom: "5px" }}>
                Rs.{" "}
                {(Math.floor(Number(invoice?.data?.final_price)))}
              </p>
            </div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                For {invoice?.data?.tenant_details?.name}
              </p>
              <p style={{ fontWeight: "bold" }}>Authorized Signatory</p>
            </div>
          </div>
        </div>
        <div className="footer-right-section">
          <div
            style={{
              flex: "3",
              borderBottom: "2px solid black",
              padding: "5px",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>SUB TOTAL</p>
              { <p>SGST</p>}
              { <p>CGST</p>}
              <p>Round Off</p>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>{invoice?.data?.sub_total}</p>
              { <p>{invoice?.data?.sgst}</p>}
              { <p>{invoice?.data?.cgst}</p>}
              <p>{invoice?.data?.final_price}</p>
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <p style={{ fontWeight: "bold", fontSize: "15px", margin: "10px" }}>
              GRAND TOTAL.{" "}
            </p>
            <p
              style={{
                marginTop: "10px",
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Rs. {Math.floor(invoice?.data?.final_price)}
            </p>
          </div>
        </div>
      </div> */}
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            generatePDF();
          }}
          style={{
            backgroundColor: "#60e1cb",
            padding: "18px",
            borderRadius: "5px",
            color: "white",
            fontWeight: "450",
            minHeight: "20px",
            fontSize: "0.9em",
            marginTop: "10px",
            border: "none",
            margin: "10px",
            maxWidth: "90px",
            marginLeft: "90%",
          }}
        >
          Download
        </button>
      </div>
    </>
  );
}
